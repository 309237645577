<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- (기간)조치완료 요청일 -->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="LBL0001410"
            name="actionCompleteRequestDates"
            v-model="searchParam.actionCompleteRequestDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 업무 -->
          <c-select
            :disabled="isPop===true?true:false"
            codeGroupCd="IBM_TASK_TYPE_CD"
            :rejectItems="['ITT0000145']"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmTaskTypeCd"
            label="LBLWORK"
            v-model="searchParam.ibmTaskTypeCd"
          ></c-select>
        </div>
        <div v-if="isPop===true ? false : true" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 진행단계 -->
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 요청부서 -->
          <c-dept 
            :isFirstValue="false"
            type="search" 
            label="LBL0001421" 
            name="improveRequestDeptCd" 
            v-model="searchParam.improveRequestDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 조치부서 -->
          <c-dept 
            :isFirstValue="isPop === true ? false : true"
            type="search" 
            label="LBL0001417" 
            name="actionDeptCd" 
            v-model="searchParam.actionDeptCd" />
        </div>
        <div v-if="isPop===true ? false : true" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 개선구분 -->
          <c-select
            codeGroupCd="IBM_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ibmClassCd"
            label="LBL0001411"
            v-model="searchParam.ibmClassCd"
          ></c-select>
        </div>
        <div v-if="isPop===true ? false : true" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 지연된 업무 확인 -->
          <c-checkbox
            :isFlag="true"
            falseLabel="All"
            label="LBL0001412"
            name="overFlag"
            v-model="searchParam.overFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="개선 목록"
      tableId="impr01"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="gridHeight"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 개선결과보고서 -->
          <c-btn 
            :disabled="!grid.data || grid.data.length === 0"
            label="LBL0001425" 
            icon="print"
            @btnClicked="imprResultPrint" />
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addEtcImpr" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='actionCompleteRequestDate'">
          <span :style="props.row.approvalStatusCd === 'ASC9999999' ? 'text-decoration: line-through' : ''">
            {{props.row.actionCompleteRequestDate}}
            <!-- completeDateOver: {{props.row.completeDateOver}}
            ibmStepCd: {{props.row.ibmStepCd}}
            approvalStatusCd: {{props.row.approvalStatusCd}} -->

            <!-- 지연 -->
            <q-badge v-if="props.row.completeDateOver > 0
              && props.row.ibmStepCd !== 'IS00000001' && props.row.approvalStatusCd !== 'ASC9999999'" color="negative" :label="$label('LBL0001414')" class="blinking" />
          </span>
          <span v-if="props.row.approvalStatusCd === 'ASC9999999'">
            👉 {{props.row.targetDate}}
          </span>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
export default {
  name: 'impr',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          plantCd: null,
          ibmTaskTypeCd: null,
          actionCompleteRequestDates: [],
          stepGubun: '',
        }
      },
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        actionCompleteRequestDates: [],
        ibmTaskTypeCd: null,
        stepCd: null,
        improveRequestDeptCd: '',
        actionDeptCd: '',
        actionFlag: 'D',
        overFlag: 'N',
        ibmClassCd: null,
        stepGubun: '',
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            // 업무
            label: 'LBLWORK',
            align: 'center',
            style: 'width:120px',
            sortable: true
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            // 제목
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            // 진행단계
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            // 요청부서
            label: 'LBL0001421',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            // 요청정보
            label: 'LBL0001420',
            align: 'left',
            style: 'width:200px',
            type: 'html',
            sortable: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            // 조치완료 요청일
            label: 'LBL0001419',
            align: 'center',
            style: 'width:100px',
            type: 'custom',
            sortable: true,
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            // 지연 결재
            label: 'LBL0001418',
            align: 'center',
            sortable: true,
            style: 'width:90px',
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0001417',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionUserNames',
            field: 'actionUserNames',
            // 조치자
            label: 'LBL0001416',
            align: 'left',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'actionCompleteDate',
            field: 'actionCompleteDate',
            // 조치완료일
            label: 'LBL0001426',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      printUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isPop() {
      return Boolean(this.popupParam.ibmTaskTypeCd)
    },
    gridHeight() {
      return this.isPop ? '500px' : null
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      this.printUrl = selectConfig.sop.ibm.improve.print.url
      // code setting
      if (this.popupParam.ibmTaskTypeCd) {
        this.searchParam.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
      }
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      if (this.popupParam.actionCompleteRequestDates) {
        this.searchParam.actionCompleteRequestDates = this.popupParam.actionCompleteRequestDates;
      }
      if (this.popupParam.stepGubun) {
        this.searchParam.stepGubun = this.popupParam.stepGubun;
      }
      if (this.isPop) {
        this.searchParam.ibmClassCd = 'IC00000001' // 개선요청만
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    addEtcImpr() {
      this.linkClick()
    },
    linkClick(row, col, index) {
      if (!row || row.ibmClassCd == 'IC00000001') {// 즉시조치
        this.popupOptions.title = 'LBL0001415'; // 개선 상세
        this.popupOptions.isFull = true;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
          ibmTaskUnderTypeCd: row ? row.ibmTaskUnderTypeCd : 'ITTU000230',
        };
        this.popupOptions.target = () => import(`${'./imprDetail.vue'}`);
      } else {
        this.popupOptions.title = 'LBLIMMEDIATE';
        this.popupOptions.isFull = false;
        this.popupOptions.param = {
          sopImprovementId: row ? row.sopImprovementId : '',
          ibmTaskTypeCd: row ? row.ibmTaskTypeCd : 'ITT9999999',
          ibmTaskUnderTypeCd: row ? row.ibmTaskUnderTypeCd : 'ITTU000230',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    imprResultPrint() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request(
        _result => {
          let fileOrgNm = '개선 결과 보고서_' + this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>
